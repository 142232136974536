export default {
  methods: {
    $countdown(distance) {
      distance = distance / 1000;
      this.clockInterval = setInterval(() => {
        distance--;
        let minutes = Math.floor(distance / 60);
        let seconds = Math.floor(distance % 60);
        if (minutes < 10) {
          minutes = "0" + minutes;
        }
        if (seconds < 10) {
          seconds = "0" + seconds;
        }
        let x = minutes + ":" + seconds;
        if (distance < 0) {
          this.countdownTimer = "00:00";
          this.isCounterExpired = true;
          clearInterval(this.clockInterval);
        } else {
          this.countdownTimer = x;
        }
      }, 1000);
    },
  },
};
